<template>
  <moe-page title="查看结果" class="survey-record">
    <moe-tabs type="border-card" :tabsList="[{ label: '问卷报告' }, { label: '数据详情' }]" @change="handleChange">
      <template #0>
        <div class="survey-record-questions">
          <div class="mb-30" v-for="(item, index) in questions" :key="index">
            <div class="ml-50 mb-20 fwb font-20">{{ `${index + 1}.${item.title}` }}</div>
            <div class="ml-50 mb-20 color-gray2 font-16">{{ `填写率 ${item.answerRate}% / 填写 ${item.answerCount}` }}</div>

            <div class="ml-50 mb-20" v-if="item.images">
              <moe-image class="mr-5" :src="imageItem" v-for="(imageItem, imageIndex) in item.images.split(',')" :key="imageIndex" width="200px" height="200px" />
            </div>

            <template v-if="['RADIO', 'CHECK'].includes(item.type)">
              <div class="survey-record-questions__progress" v-for="(optionItem, optionsIndex) in item.options" :key="optionsIndex">
                <div class="survey-record-questions__progress__content box-shadow">
                  <div class="survey-record-questions__progress__content__title">{{ optionItem.title }}</div>
                  <div class="df aic">
                    <div class="min-width-100 z-index-2">{{ optionItem.count }}</div>
                    <div class="min-width-100 z-index-2">{{ `${optionItem.rate}%` }}</div>
                  </div>
                  <div class="survey-record-questions__progress__content__bar" :style="{ 'width': `${optionItem.rate}%` }"></div>
                </div>
              </div>
            </template>

            <template v-if="['TEXT'].includes(item.type)">
              <div class="ml-30 mr-30">
                <moe-table :numberShow="false" :data="item.textAnswers" :mode="false">
                  <el-table-column label="编号" width="100" prop="id" />
                  <el-table-column label="文本答案" prop="text" :show-overflow-tooltip="true" />
                </moe-table>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template #1>
        <moe-inquire @search="surveyRecordSearch">
          <el-form-item label="提交时间">
            <el-date-picker
              style="width: 100%;"
              placement="bottom-start"
              v-model="datetime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change="(datetime) => ([surveyParams.startTime, surveyParams.endTime] = datetime || ['', ''])" />
          </el-form-item>
        </moe-inquire>
        <moe-table v-if="tabsIndex === 1" ref="surveyRecordTableRef" url="/shop/survey/resultDetail" :params="surveyParams" :numberShow="false" :mode="mode">
          <el-table-column label="编号" width="100" prop="id" />
          <el-table-column label="开始答题时间" width="150" prop="startTime" />
          <el-table-column label="结束答题时间" width="150" prop="endTime" />
          <el-table-column label="答题时长" width="150" :formatter="formatterTime" />
          <template v-if="questions.length">
            <el-table-column :label="item.title" min-width="150" v-for="(item, index) in questions" :key="index" :show-overflow-tooltip="true">
              <template slot="header">
                <div class="df aic jcc">
                  <div class="mr-10">{{ `${index + 1}.题目标题` }}</div>
                  <moe-tooltip placement="top" :content="item.title">
                    <i class="el-icon-warning-outline font-24"></i>
                  </moe-tooltip>
                </div>
              </template>
              <template slot-scope="{ row }">
                {{ row.answers[index].value || '-' }}
              </template>
            </el-table-column>
          </template>
        </moe-table>
      </template>
    </moe-tabs>
  </moe-page>
</template>

<script>
export default {
  name: 'QuestionnaireSurveyRecord',
  data() {
    return {
      tabsIndex: 0,
      mode: false,
      surveyParams: {
        id: '',
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
      },
      datetime: [],
      questions: []
    }
  },
  methods: {
    formatterTime({ startTime, endTime }) {
      return this.$moe_time.countDown(endTime, startTime);
    },
    surveyRecordSearch(isSearch) {
      this.mode = true;
      if (!isSearch) {
        this.surveyParams = {
          id: this.$route.query.id,
          pageNum: 1,
          pageSize: 10,
          startTime: '',
          endTime: '',
        }
        this.datetime = [];
      }

      this.$refs['surveyRecordTableRef'].searchData();
    },
    handleChange({ index }) {
      this.mode = true;
      this.tabsIndex = Number(index);
      this.surveyParams.pageNum = 1;
      this.surveyParams.pageSize = 10;
      if (this.tabsIndex === 0) {
        this.req_getSurveyResultReport();
      }
    },
    /** 问卷结果报告 */
    async req_getSurveyResultReport() {
      const { code, result, message } = await this.$moe_api.QUESTIONNAIRESURVEY_API.getSurveyResultReport({ id: this.surveyParams.id });
      if (code === 200) {
        this.questions = result.questions;
      } else {
        this.$moe_msg.error(message);
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.surveyParams.id = this.$route.query.id;
      this.req_getSurveyResultReport();
    }
  }
}
</script>

<style lang="scss" scoped>
.survey-record {
  &-questions {
    &__progress {
      margin-bottom: 20px;
      &__content {
        position: relative;
        min-height: 50px;
        display: flex;
        align-items: center;
        margin: 0 50px;
        padding: 0 30px;
        border-radius: 10px;
        overflow: hidden;
        &__title {
          flex: 1;
          line-height: 30px;
          font-size: 16px;
          z-index: 2;
        }
        &__bar {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background-color: $--color-primary;
        }
      }
    }
  }
}
</style>